import React, { useEffect, useRef, useState } from "react"
import {
  ContainerRow,
  ImagePart,
  Info,
  List,
  Price,
  ServicesContainer,
  TextPart,
  Wrapper,
} from "../../components/businessRelocationStyled"
import landingImage from "../../assets/landingImage.png"
import { SubmitButton, ToggleH2Styled } from "../../components/careercomponents/careerDroping/dropStyle"
import {
  H2Styled, NavLink, SeemoreWrapper,
  ServiceTitle,
} from "../../components/homecomponents/homeServices/homeServiceStyle"
import useTranslations from "../../components/useTranslations"
import ServiceDropWrap from "../../components/servicecomponents/serviceDrop/servicedrop"
import CalcImg from "../../assets/homeImages/icons/calculator.svg"
import { FormColumn } from "../../components/contactcomponts/contactMainStyle"
import FormindIviduals from "../../components/contactcomponts/contactForm/formindividuals"
import triple from "../../api/triple"
import apiUrl from "../../api/api.json"
import UsefulNews from "../../components/news/secondnewspage"
import SharedLogo from "../../assets/sharedLogo/share_logo(3c).png"
import SEO from "../../components/seo"

const Index = ({ pageContext, langText, lang }) => {
  const { home, contact , moveBusinessToArmenia} = useTranslations()
  const [serviceData, setserviceData] = useState([
    {
      data: {
        id: 0,
        scroll_id: `test_1`,
        image: CalcImg,
        paragraph: `${moveBusinessToArmenia.serviceTitle1}`,
        text: `● ${moveBusinessToArmenia.firstService1}\n● ${moveBusinessToArmenia.firstService2}\n● ${moveBusinessToArmenia.firstService3}`,
      },
      open: false,
    },
    {
      data: {
        id: 1,
        scroll_id: `test_2`,
        image: CalcImg,
        paragraph: `${moveBusinessToArmenia.serviceTitle2}`,
        text: `● ${moveBusinessToArmenia.secondService1}\n● ${moveBusinessToArmenia.secondService2}\n● ${moveBusinessToArmenia.secondService3}\n● ${moveBusinessToArmenia.secondService4}\n● ${moveBusinessToArmenia.secondService5}`,
      },
      open: false,
    },
    {
      data: {
        id: 2,
        scroll_id: `test_3`,
        image: CalcImg,
        paragraph: `${moveBusinessToArmenia.serviceTitle3}`,
        text: `● ${moveBusinessToArmenia.thirdService1}\n● ${moveBusinessToArmenia.thirdService2}`,
      },
      open: false,
    },
    {
      data: {
        id: 3,
        scroll_id: `test_4`,
        image: CalcImg,
        paragraph: `${moveBusinessToArmenia.serviceTitle4}`,
        text: `● ${moveBusinessToArmenia.forthService1}\n● ${moveBusinessToArmenia.forthService2}\n● ${moveBusinessToArmenia.forthService3}\n● ${moveBusinessToArmenia.forthService4}\n`,
      },
      open: false,
    },
    {
      data: {
        id: 4,
        scroll_id: `test_4`,
        image: CalcImg,
        paragraph: `${moveBusinessToArmenia.serviceTitle5}`,
        text: `● ${moveBusinessToArmenia.fifthService1}\n● ${moveBusinessToArmenia.fifthService2}`,
      },
      open: false,
    },
  ])
  const [data, setData] = useState()
  const form = useRef(null)
  const [USDCurrency, setUSDCCurrency] = useState()
  const [RUBCurrency, setRUBCCurrency] = useState()
  const [buttonDisplay, setButtonDisplay] = useState(false)
  const toggle = (current) => {
    const data = serviceData.map(d => {
      if (d.data.id === current.data.id && d.open === false) {
        return { ...d, open: true }
      }
      return { ...d, open: false }
    })
    setserviceData(data)
  }
  useEffect(() => {
    triple.get("/api/news")
      .then(res => {
        const sorted = res.data.data.slice().sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        const filter = sorted.filter((item) => item.id === 73 || item.id === 72 || item.id === 71 || item.id === 66 )
        setData(filter)
      })
      .catch(err => console.log(err))

    const rates = {}
    const config = { params: { filters: ["USD", "EUR", "RUB", "GEL", "GBP", "CNY"] } }

    triple.get("/api/rates/last", config)
      .then(res => {
        Object.values(res.data.rates).forEach(rate => rate["ISO"]==="USD" && setUSDCCurrency(parseFloat(rate["Rate"])))
        Object.values(res.data.rates).forEach(rate => rate["ISO"]==="RUB" && setRUBCCurrency(parseFloat(rate["Rate"])))

        this.setState({ rates })
      })
      .catch(err => console.log(err))
  }, [])

  return (
    <Wrapper>
      <SEO
        title={moveBusinessToArmenia.title}
        description={moveBusinessToArmenia.description}
        pageContext={pageContext}
        image={SharedLogo}
      />
      <Info>
        <ImagePart>
          <img src={landingImage} alt="" />
        </ImagePart>
        <TextPart>
          <ToggleH2Styled landing>{moveBusinessToArmenia.pageTitle}</ToggleH2Styled>
          <p>{moveBusinessToArmenia.description}</p>
        </TextPart>
      </Info>
      <ServiceTitle landing>
        <H2Styled>{moveBusinessToArmenia.services}</H2Styled>
      </ServiceTitle>
      <ContainerRow>
        {serviceData.map((d, id) => (
          <ServiceDropWrap showServiceForm={toggle} data={d} key={id} />
        ))}
      </ContainerRow>
      <ServiceTitle landing>
        <H2Styled>{moveBusinessToArmenia.whyArmenia}</H2Styled>
      </ServiceTitle>
      <ServicesContainer whyArmenia landing>
        <List checked>{moveBusinessToArmenia.reason1}</List>
        <List checked>{moveBusinessToArmenia.reason2}</List>
        <List checked>{moveBusinessToArmenia.reason3}</List>
        <List checked>{moveBusinessToArmenia.reason4}</List>
        <List checked>{moveBusinessToArmenia.reason5}</List>
        <List checked>{moveBusinessToArmenia.reason6}</List>
      </ServicesContainer>
      <ServiceTitle landing>
        <H2Styled landing>{moveBusinessToArmenia.whyTriple}</H2Styled>
      </ServiceTitle>
      <ServicesContainer whyArmenia landing>
        <List checked>{moveBusinessToArmenia.tripleReason1}</List>
        <List checked>{moveBusinessToArmenia.tripleReason2}</List>
        <List checked>{moveBusinessToArmenia.tripleReason3}</List>
        <List checked>{moveBusinessToArmenia.tripleReason4}</List>
        <List checked>{moveBusinessToArmenia.tripleReason5}</List>
      </ServicesContainer>
      <ServiceTitle landing>
        <H2Styled>{moveBusinessToArmenia.packages}</H2Styled>
      </ServiceTitle>
      <ContainerRow>
        <ServicesContainer landing>
          <h4>{moveBusinessToArmenia.package} 1</h4>
          <Price><span>{moveBusinessToArmenia.since}</span><h2>50.000</h2><span>{moveBusinessToArmenia.amd}</span></Price>
          <p className={"currency"}><span>{Math.round(50000/USDCurrency)}$</span> {moveBusinessToArmenia.usdPrice}</p>
          <p className={"currency"}><span>{Math.round(50000/RUBCurrency)}₽</span> {moveBusinessToArmenia.usdPrice}</p>
          <List checked>{moveBusinessToArmenia.packageService1}</List>
          <List checked>{moveBusinessToArmenia.packageService2}</List>
          <List checked>{moveBusinessToArmenia.packageService3}</List>
          <List checked>{moveBusinessToArmenia.packageService4}</List>
          <List>{moveBusinessToArmenia.packageService5}</List>
          <List>{moveBusinessToArmenia.packageService6}</List>
          <List checked>{moveBusinessToArmenia.packageService7}</List>
          <List checked>{moveBusinessToArmenia.packageService8}</List>
          <List>{moveBusinessToArmenia.packageService9}</List>
          <List>{moveBusinessToArmenia.packageService10}</List>
            <SeemoreWrapper onClick={()=>form.current.scrollIntoView()} className="relocation submit_button services_submit">
              {moveBusinessToArmenia.apply}
            </SeemoreWrapper>
        </ServicesContainer>
        <ServicesContainer landing>
          <h4>{moveBusinessToArmenia.package} 2</h4>
          <Price><span>{moveBusinessToArmenia.since}</span><h2>100.000</h2><span>{moveBusinessToArmenia.amd}</span></Price>
          <p className={"currency"}><span>{Math.round(100000/USDCurrency)}$</span> {moveBusinessToArmenia.usdPrice}</p>
          <p className={"currency"}><span>{Math.round(100000/RUBCurrency)}₽</span> {moveBusinessToArmenia.usdPrice}</p>
          <List checked>{moveBusinessToArmenia.packageService1}</List>
          <List checked>{moveBusinessToArmenia.packageService2}</List>
          <List checked>{moveBusinessToArmenia.packageService3}</List>
          <List checked>{moveBusinessToArmenia.packageService4}</List>
          <List checked>{moveBusinessToArmenia.packageService5}</List>
          <List>{moveBusinessToArmenia.packageService6}</List>
          <List checked>{moveBusinessToArmenia.packageService7}</List>
          <List checked>{moveBusinessToArmenia.packageService8}</List>
          <List checked>{moveBusinessToArmenia.packageService9}</List>
          <List>{moveBusinessToArmenia.packageService10}</List>
          <SeemoreWrapper onClick={()=>form.current.scrollIntoView()} className="relocation submit_button services_submit">
            {moveBusinessToArmenia.apply}
          </SeemoreWrapper>
        </ServicesContainer>
        <ServicesContainer landing>
          <h4>{moveBusinessToArmenia.package} 3</h4>
          <Price><span>{moveBusinessToArmenia.since}</span><h2>200.000</h2><span>{moveBusinessToArmenia.amd}</span></Price>
          <p className={"currency"}><span>{Math.round(200000/USDCurrency)}$</span> {moveBusinessToArmenia.usdPrice}</p>
          <p className={"currency"}><span>{Math.round(200000/RUBCurrency)}₽</span> {moveBusinessToArmenia.usdPrice}</p>
          <List checked>{moveBusinessToArmenia.packageService1}</List>
          <List checked>{moveBusinessToArmenia.packageService2}</List>
          <List checked>{moveBusinessToArmenia.packageService3}</List>
          <List checked>{moveBusinessToArmenia.packageService4}</List>
          <List checked>{moveBusinessToArmenia.packageService5}</List>
          <List checked>{moveBusinessToArmenia.packageService6}</List>
          <List checked>{moveBusinessToArmenia.packageService7}</List>
          <List checked>{moveBusinessToArmenia.packageService8}</List>
          <List checked>{moveBusinessToArmenia.packageService9}</List>
          <List checked>{moveBusinessToArmenia.packageService10}</List>
          <SeemoreWrapper onClick={()=>form.current.scrollIntoView()} className="relocation submit_button services_submit">
            {moveBusinessToArmenia.apply}
          </SeemoreWrapper>
        </ServicesContainer>
      </ContainerRow>
      <ServiceTitle landing>
        <H2Styled>{moveBusinessToArmenia.faq}</H2Styled>
      </ServiceTitle>
      {data && (
        <UsefulNews
          landing
          locale={pageContext.locale}
          apiUrl={apiUrl.apiUrl}
          news={data}
          lang={pageContext.locale}
          pageContext={pageContext}
          buttonDisplay={buttonDisplay}
          setButtonDisplay={setButtonDisplay}
        />
      )}
      <FormColumn ref={form} landing>
        <FormindIviduals landing langtext={contact.form_content} lang={pageContext.locale} />
      </FormColumn>
    </Wrapper>
  )
}

export default Index